import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIntl } from "react-intl";
import { ReactComponent as Close } from "../../../../assets/icons/icon-close.svg";
import { useForm } from "../../../../hooks/FormGeneratorHooks";
import ButtonUI from "../../../ButtonUI";
import { Formiz } from "../StepperWrapper/Formiz";
import { renderField } from "./RenderField";
import { useParams } from "react-router-dom";
import { getformulaValues } from "../../../../api/study";

const PropertyEditor = (props: any) => {
  const { element, show, onClose, onSubmit, elements } = props; // TODO edit element prop to include new editableProp for cross role data display trigger
  console.log("🚀 ~ file: index.tsx:22 ~ PropertyEditor ~ elements:", element)
  const { type, id, dropEffect, field_name, ...editableProps } = element || {
    type: null,
    id: null,
    dropEffecct: null,
    field_name: null,
  };
  console.log("🚀 ~ file: index.tsx:21 ~ PropertyEditor ~ type:", type);
  const [formulaValues, setFormulaValues] = useState([]) as any[];
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(id ? id : null);
  const { t } = useTranslation();
  const intl = useIntl();
  const form = useForm();
  const params = useParams() as any;
  const { studyId } = params;
  useEffect(() => {
    setSelected(id);
    form.setFieldsValues(editableProps);
  }, [id]);

  useEffect(() => {
    if (!visible) onClose?.();
  }, [visible, onClose]);
  useEffect(() => {
    if (show) setVisible(show);
  }, [show]);

  const handleSubmit = (values) => {
    if (values.options) {
      values.options = values.options.map((i) => {
        return { label: i.label, value: i.label };
      });
    }
    onSubmit?.(values);
    setVisible(false);
  };
  const getformulaValuesApi = async () => {
    const res = await getformulaValues(studyId);
    console.log("🚀 ~ file: index.tsx:60 ~ getformulaValuesApi ~ res:", res);
    let newaArray: any[] = [];

    if (res && res.formulaValues) {
      res.formulaValues.map((fv: any) => {
        newaArray.push({
          label: `${fv.label}-${fv.formName}-${fv.sectionName}`,
          value: fv.key,
          type: fv.type,
          isOperator: false,

          __isNew__: false,
        });
      });

      setFormulaValues([...newaArray]);
    }
  };
  useEffect(() => {
    if (type === "CalculatedInput") {
      getformulaValuesApi();
    }
  }, [type]);

  return (
    <Drawer
      isOpen={visible}
      placement="right"
      onClose={() => setVisible(false)}
      size="lg"
    >
      <DrawerOverlay />
      <DrawerContent
        className="rounded-2xl modalFieldEditor "
        overflowY={"scroll"}
      >
        <ButtonUI
          status="icon-secondary"
          onClick={() => setVisible(false)}
          className="absolute right-2 top-1 "
        >
          <Close />
        </ButtonUI>
        <DrawerHeader
          style={{ fontSize: "26px" }}
          borderBottomWidth="1px"
          className="my-4"
        >
          {t("missingWording.editProp")}
        </DrawerHeader>
        <Formiz
          key={form.resetKey}
          connect={form}
          onValidSubmit={handleSubmit}
          initialValues={editableProps}
        >
          <form noValidate onSubmit={form.submit}>
            <DrawerBody overflowX={"hidden"}>

              {/* TODO add editable prop for other role display trigger */}
              {Object.keys(editableProps).map((name) => {
                console.log("[DEBUG] editableProps item : ",  {name, value: editableProps[name]})
                if (
                  (name === "accept" && form.values["fileType"] === "image") ||
                  name !== "accept"
                )
                  return renderField({
                    name,
                    value: editableProps[name],
                    form: form,
                    elements,
                    selectedElm: selected,
                    intl: intl,
                    setField: form.setFieldsValues,
                    allElements: props.formSections,
                    externalFields: formulaValues,
                  });
                return null;
              })}
            </DrawerBody>

            <DrawerFooter borderTopWidth="1px" justifyContent={"space-between"}>
              <ButtonUI
                className="py-2 px-8  btn-delete "
                onClick={(e) => setVisible(false)}
                status="secondary"
                type="button"
                id="btn-delete"
              >
                {intl.formatMessage({ id: "cancelButton" })}
              </ButtonUI>
              <ButtonUI
                height="42px"
                status="primary"
                className="py-2 px-8  h-10  saveEditBtnTuto "
                disabled={!form.isValid}
                type="submit"
              >
                {intl.formatMessage({ id: "submitButton" })}
              </ButtonUI>
            </DrawerFooter>
          </form>
        </Formiz>
      </DrawerContent>
    </Drawer>
  );
};
export default PropertyEditor;
