import { Grid, GridItem } from "@chakra-ui/react";
import { FormMethods } from "../../../../hooks/FormGeneratorHooks/types/form.types";
import { FormElement } from "../../Elements";
import { FieldInput } from "../../Elements/FieldInput";
import { SwitchInput } from "../../Elements/FieldSwitcher";
import CalculConfig from "./CalculationFieldConfig";
import PhonePrefixConfig from "./PhoneFieldConfig";
import { RepeaterForLignes } from "./RepeaterLgnes";
import { Repeater } from "./RepeaterOptions";
import Rules from "./RulesValidations";
import UnitConfig from "./UnitFieldConfig";


const camel2title = (camelCase) => {
  if (Array.isArray(camelCase)) camelCase = camelCase[camelCase.length - 1];
  if (typeof camelCase === "string")
    return camelCase
      .replace(/([A-Z])/g, (match) => ` ${match}`)
      .replace(/^./, (match) => match.toUpperCase())
      .trim();
  else return camelCase;
};
interface Props {
  name: any;
  value: any;
  form?: FormMethods;

  elements?: any;
  selectedElm?: any;
  intl?: any;
  setField?: any;
  elment?: any;
  allElements?: any[];
  externalFields?: any[];
}

function filterNumberInput(array, selectedElm, allElements) {
  let v: any[] = [];
  allElements.map((s) =>
    s.task_data.map((f) => {
      v.push(f);
    })
  );
  console.log(
    "🚀 ~ file: RenderField.tsx:39 ~ filterNumberInput ~ allElements:",
    v
  );

  const res = v
    .filter(
      (e) =>
        (e.type === "NumberInput" ||
          e.type === "CalculatedInput" ||
          e.type === "DatePicker") &&
        e.id !== selectedElm
    )
    .map((x, i) => {
      return {
        label: `${x.label}`,
        value: x.field_name,
        type: x.type,
        isOperator: false,
        __isNew__: false,
      };
    });

  const OPERATORS = [
    {
      label: "+",
      value: "+",
      isOperator: true,
      __isNew__: false,
      type: "operat",
    },
    {
      label: "-",
      value: "-",
      isOperator: true,
      __isNew__: false,
      type: "operat",
    },
    {
      label: "*",
      value: "*",
      isOperator: true,
      __isNew__: false,
      type: "operat",
    },
    {
      label: "/",
      value: "/",
      isOperator: true,
      __isNew__: false,
      type: "operat",
    },
    {
      label: "(",
      value: "(",
      isOperator: false,
      __isNew__: false,
      type: "operat",
    },
    {
      label: ")",
      value: ")",
      isOperator: false,
      __isNew__: false,
      type: "operat",
    },
  ];
  return res ? res.concat(OPERATORS) : OPERATORS;
}
function eliminateDuplicates(arr: any[]) {
  const uniqueValues = new Set();
  const result: any[] = [];

  for (const item of arr) {
    if (!uniqueValues.has(item.value)) {
      result.push(item);
      uniqueValues.add(item.value);
    }
  }

  return result;
}

// TODO handle rendering for name = triggerOutOfScopeVisitDisplay
const renderField = ({
  name,
  value,
  form,
  elements,
  selectedElm,
  intl,
  setField,
  elment,
  allElements,
  externalFields = [],
}: Props) => {
  let type = "TextArea";
  let options: any = [];
  
  let dirOptions = [
    { label: intl.formatMessage({ id: "horzi" }), value: "row" },
    { label: intl.formatMessage({ id: "verti" }), value: "column" },
  ];

  switch (typeof value) {
    case "boolean":
      options = [
        { label: intl.formatMessage({ id: "Yes" }), value: true },
        { label: intl.formatMessage({ id: "No" }), value: false },
      ];
      return (
        <Grid
          width={"60%"}
          templateColumns="repeat(2, 1fr)"
          alignItems={"baseline"}
          gap={1}
        >
          <GridItem fontSize={"16px"} w="180px" h="10">
            {" "}
            <span className=" text-sm text-justify">
              {intl.formatMessage({ id: name })}
            </span>
          </GridItem>
          <GridItem w="100%" h="10" className="flex  text-xs text-gray-500  ">
            <GridItem>
              <span className="p-2  card-subtitle">
                {intl.formatMessage({ id: "desactive" })}
              </span>
            </GridItem>
            <GridItem>
              {" "}
              <SwitchInput value={value} name={name} />
            </GridItem>
            <GridItem>
              <span className="p-2 card-subtitle">
                {intl.formatMessage({ id: "activer" })}
              </span>
            </GridItem>
          </GridItem>
        </Grid>
      );
    case "string":
      if (name === "decimalPlaces") {
        return (
          <FieldInput
          name={`decimalPlaces`}
          type="number"
          value={value}
          label={intl.formatMessage({ id: name })}
          hasUnit={{ active: true, unit: intl.formatMessage({ id: 'digitsAfter' }) }}
          min={0}
          max={10}
        />
        );
      }
      if (name === "content") type = "Editor";
      else if (name === "src") {
        type = "Photo2";
      } else if (name === "direction") {
        type = "Dropdown";
      } else if (name === "fileType") {
        type = "Dropdown";
      } else type = "TextInput";

      return (
        <FormElement
          element={{
            field_name: name,
            options:
              name === "direction"
                ? dirOptions
                : name === "fileType"
                ? [
                    { value: "image", label: "Image" },
                    { value: "file", label: "File" },
                  ]
                : options,
            label: intl.formatMessage({ id: name }),
            type: type,
          }}
          isPreview={true}
          selectedElm={selectedElm}
          value={value}
          readOnly={false}
          form={form}
          required={name === "label" ? true : false}
          fieldOnSubmit={false}
          sharedCalculVars={false}
          sharedStatsVars={false}
          isEpro={false}
        />
      );
    case "number":
      type = "NumberInput";
      if (name === "decimalPlaces") {
        return (
          <FieldInput
            name={`decimalPlaces`}
            type="number"
            value={value}
            label={intl.formatMessage({ id: name })}
            hasUnit={{ active: true, unit: intl.formatMessage({ id: 'digitsAfter' }) }}
            min={0}
            max={10}
          />
        );
      }
      return (
        <FormElement
          key={name}
          value={value}
          isPreview={true}
          element={{
            field_name: name,
            options: options,
            label: camel2title(intl.formatMessage({ id: name })),
            type: type,
          }}
          readOnly={false}
          form={form}
          fieldOnSubmit={false}
          sharedCalculVars={false}
          sharedStatsVars={false}
          isEpro={false}
        />
      );
    case "object":
      if (name === "options") {
        return (
          <Repeater
            key={name}
            form={form}
            value={value}
            withOption={
              selectedElm?.withOption ? selectedElm.withOption : false
            }
          />
        );
      }
      if (name === "lignes") {
        return <RepeaterForLignes key={name} form={form} value={value} />;
      }

      if (name === "conditionLogic") {
        type = "conditionLogic";
        options = [
          { label: intl.formatMessage({ id: "Yes" }), value: "true" },
          { label: intl.formatMessage({ id: "No" }), value: "false" },
        ];

        return (
          <FormElement
            selectedElm={selectedElm}
            elements={elements}
            value={value}
            element={{
              field_name: name,
              label: intl.formatMessage({ id: name }),
              type: type,
            }}
            readOnly={false}
            form={form}
            fieldOnSubmit={false}
            isPreview={true}
            sharedCalculVars={false}
            sharedStatsVars={false}
            isEpro={false}
          />
        );
      }

      // TODO develop here
      if (name === "triggerOutOfScopeVisitDisplay") {
        type = "triggerOutOfScopeVisitDisplay";
        options = [
          { label: intl.formatMessage({ id: "Yes" }), value: "true" },
          { label: intl.formatMessage({ id: "No" }), value: "false" },
        ];

        return (
          <FormElement
            selectedElm={selectedElm}
            elements={elements}
            value={value}
            element={{
              field_name: name,
              label: intl.formatMessage({ id: name }),
              type: type,
            }}
            readOnly={false}
            form={form}
            fieldOnSubmit={false}
            isPreview={true}
            sharedCalculVars={false}
            sharedStatsVars={false}
            isEpro={false}
          />
        );
      }

      else if (name === "src") {
        type = "Photo2";
        return (
          <FormElement
            element={{
              field_name: name,
              options: options,
              label: intl.formatMessage({ id: name }),
              type: type,
            }}
            selectedElm={selectedElm}
            value={value}
            readOnly={false}
            form={form}
            required={name === "label" ? true : false}
            fieldOnSubmit={false}
            isPreview={true}
            sharedCalculVars={false}
            sharedStatsVars={false}
            isEpro={false}
          />
        );
      } else if (name === "rules") {
        return (
          <Rules
            key={name}
            name={name}
            disabled={false}
            value={value}
            element={elment}
            selectedElm={selectedElm}
            elements={elements}
            form={form}
          />
        );
      } else if (name === "hasUnit") {
        return (
          <UnitConfig
            key={name}
            name={name}
            disabled={false}
            value={value}
            element={elment}
            selectedElm={selectedElm}
            elements={elements}
            form={form}
          />
        );
      } else if (name === "hasprefix") {
        return (
          <PhonePrefixConfig
            key={name}
            name={name}
            disabled={false}
            value={value}
            element={elment}
            selectedElm={selectedElm}
            elements={elements}
            form={form}
          />
        );
      } else if (name === "calculationConfig") {
        let listOfAllowedField = eliminateDuplicates([
          ...filterNumberInput(elements, selectedElm, allElements),
          ...externalFields,
        ]);

        return (
          <CalculConfig
            key={name}
            name={name}
            disabled={false}
            value={value}
            element={elment}
            selectedElm={selectedElm}
            elements={elements}
            form={form}
            listOfAllowedField={listOfAllowedField}
          />
        );
      } else if (name === "childFieldsConfig") {
        type = "childFieldsConfig";
        options = [
          { label: intl.formatMessage({ id: "Yes" }), value: "true" },
          { label: intl.formatMessage({ id: "No" }), value: "false" },
        ];
        return (
          <FormElement
            selectedElm={selectedElm}
            elements={elements}
            value={value}
            element={{
              field_name: name,
              label: intl.formatMessage({ id: name }),
              type: type,
            }}
            readOnly={false}
            form={form}
            fieldOnSubmit={false}
            isPreview={true}
            sharedCalculVars={false}
            sharedStatsVars={false}
            isEpro={false}
          />
        );
      } else break;

    default:
      type = "TextArea";
      return (
        <FormElement
          key={name}
          value={value}
          element={{
            field_name: name,
            options: options,
            label: camel2title(name),
            type: type,
          }}
          readOnly={undefined}
          form={form}
          fieldOnSubmit={false}
          sharedCalculVars={false}
          sharedStatsVars={false}
          isEpro={false}
        />
      );
  }
};

export { renderField };

