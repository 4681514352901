import { atom } from "jotai";
import { User } from "../types/user";
import { v4 as uuidv4 } from "uuid";

export const userAtom = atom<User | null>(null);
export const userUuidAtom = atom(uuidv4());
export const updateUserForcedVisitsVisibilityAtom = atom(
  null,
  (
    get,
    set,
    update: {
      forceShowVisits: Record<string, string[]>;
      forceHideVisits: Record<string, string[]>;
    }
  ) => {
    const currentUser = get(userAtom);

    if (currentUser) {
      const updatedUser = {
        ...currentUser,
        forceShowVisits: update.forceShowVisits,
        forceHideVisits: update.forceHideVisits,
      };

      set(userAtom, updatedUser);
    }
  }
);

