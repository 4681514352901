import { useEffect, useState, Fragment } from "react";
import { useIntl } from "react-intl";
import { DeleteIcon } from "@chakra-ui/icons";
import {
  InputGroup,
  IconButton,
  VStack,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Spinner from "../../../Spinner";
import ButtonUI from "../../../ButtonUI";
import { FormGroup } from "../../Elements/FormGroup";
import { FieldSelect } from "../../Elements/FieldSelect";
import { FieldInput } from "../../Elements/FieldInput";
import { SwitchInput } from "../../Elements/FieldSwitcher";
import { useField } from "../../../../hooks/FormGeneratorHooks";
import { useRepeater } from "../../../../hooks/FormGeneratorHooks/useRepeater";
import { conditionsListExport } from "../PropertyPageEditor/conditionList";
import { getStudyForms } from "../../../../api/study";

const OutOfScopeDisplayConfig = (props) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    otherProps,
  } = useField(props);

  const { required, name } = props;
  const { children, label, type, placeholder, helper, ...rest } = otherProps;
  const [isTouched, setIsTouched] = useState(false);
  const [visitsList, setVisitsList] = useState<any[]>([]);
  const showError = !isValid && (isTouched || isSubmitted);

  const params: any = useParams();
  const { studyId } = params;

  const { t } = useTranslation();
  const intl = useIntl();

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    name,
    ...rest,
  };

  const collection = useRepeater({
    name: "triggerOutOfScopeVisitDisplay.conditions",
    form: props.form,
    initialValues: props.value.conditions,
  });
  const conditionsList = conditionsListExport(intl);

  useEffect(() => {
    setValue(props.value);
  }, []);

  useEffect(() => {
    if (studyId)
      (async () => {
        try {
          const res = await getStudyForms(studyId);

          setVisitsList(res || []);
        } catch (error) {
          console.error(error);
        }
      })();
  }, [studyId]);

  useEffect(() => {
    setValue((val) => {
      return { ...val, conditions: value.conditions };
    });
  }, [collection.length]);

  return (
    <>
      {value && value.conditions ? (
        <>
          <FormGroup {...formGroupProps}>
            <InputGroup name="triggerOutOfScopeVisitDisplay">
              <VStack
                spacing={6}
                align="start"
                p={4}
                borderWidth={1}
                borderRadius="lg"
                width="full"
                bg="white"
              >
                <Grid
                  templateColumns="2fr 1fr"
                  gap={4}
                  width="100%"
                  alignItems="center"
                >
                  <GridItem>
                    <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                      {t("missingWording.triggerOutOfScopeVisitDisplay")}
                    </span>
                  </GridItem>

                  <GridItem display="flex" alignItems="center" className="text-xs text-gray-500">
                    <span className="card-subtitle">
                      {t("missingWording.desactive")}
                    </span>
                    <SwitchInput
                      value={props.value.status}
                      name="triggerOutOfScopeVisitDisplay.status"
                      className="mx-2"
                    />
                    <span className="card-subtitle">
                      {t("missingWording.activer")}
                    </span>
                  </GridItem>
                </Grid>

                {props.form.values.triggerOutOfScopeVisitDisplay?.status && (
                  <Grid
                    templateColumns="1fr"
                    gap={6}
                    width="100%"
                  >
                    {collection.keys.map((key, index) => (
                      <Fragment key={index}>
                        <Grid templateColumns="repeat(10, 1fr) auto" gap={2}>
                          <GridItem colSpan={2}>
                            <FieldSelect
                              name={`triggerOutOfScopeVisitDisplay.conditions[${index}].action`}
                              value={
                                Array.isArray(
                                  props.value.conditions[index]?.["action"]
                                )
                                  ? "S"
                                  : props.value.conditions[index]?.["action"]
                              }
                              options={[
                                {
                                  label: intl.formatMessage({ id: "show" }),
                                  value: "S",
                                },
                                {
                                  label: intl.formatMessage({ id: "hide" }),
                                  value: "H",
                                },
                              ]}
                              placeholder={intl.formatMessage({ id: "action" })}
                            />
                          </GridItem>

                          <GridItem colSpan={2}>
                            <FieldSelect
                              name={`triggerOutOfScopeVisitDisplay.conditions[${index}].targetVisit`}
                              value={
                                Array.isArray(
                                  props.value.conditions[index]?.["targetVisit"]
                                )
                                  ? "S"
                                  : props.value.conditions[index]?.[
                                      "targetVisit"
                                    ]
                              }
                              options={visitsList.map((visit) => ({
                                label: visit.name || "",
                                value: visit._id,
                              }))}
                              placeholder={intl.formatMessage({
                                id: "theVisit",
                              })}
                            />
                          </GridItem>

                          <GridItem display="flex" alignItems="center">
                            <span className="text-nowrap text-ellipsis overflow-hidden" style={{paddingBottom: "1rem"}}>
                              {intl.formatMessage({ id: "when" })}
                            </span>
                          </GridItem>

                          <GridItem colSpan={2}>
                            <FieldSelect
                              name={`triggerOutOfScopeVisitDisplay.conditions[${index}].ifCondition`}
                              value={
                                props.value.conditions[index]?.["ifCondition"]
                              }
                              options={conditionsList}
                            />
                          </GridItem>

                          <GridItem colSpan={2}>
                            <FieldInput
                              name={`triggerOutOfScopeVisitDisplay.conditions[${index}].value`}
                              value={props.value.conditions[index]?.["value"]}
                              placeholder={t("missingWording.value")}
                            />
                          </GridItem>

                          <GridItem
                            rowSpan={2}
                            display="flex"
                            justifyContent="center"
                          >
                            <IconButton
                              size="sm"
                              aria-label="Delete"
                              icon={<DeleteIcon />}
                              onClick={() => {
                                collection.remove(index);
                                setValue((current) => ({
                                  ...current,
                                  conditions: current.conditions.splice(
                                    index,
                                    1
                                  ),
                                }));
                              }}
                            />
                          </GridItem>
                          
                        </Grid>
                      </Fragment>
                    ))}

                    {collection.length <= 20 && (
                      <GridItem>
                        <ButtonUI
                          status="secondary"
                          className="p-2 text-xs w-48"
                          type="button"
                          onClick={() => collection.append()}
                        >
                          {t("missingWording.addCondition")}
                        </ButtonUI>
                      </GridItem>
                    )}
                  </Grid>
                )}
              </VStack>
            </InputGroup>
          </FormGroup>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default OutOfScopeDisplayConfig;
