import { ArrowRightIcon } from "@chakra-ui/icons";
import { Heading } from "@chakra-ui/react";
import { useAtom } from "jotai";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IntlProvider } from "react-intl";
import { Socket, io } from "socket.io-client";
import { autoQueringVerif } from "../../api/study";
import Modal from "../../components/ModalUI";
import Spinner from "../../components/Spinner";
import {
  MultiStepsLayout,
  PageHeader,
} from "../../components/SurveyGenerator/FormBuilder/Lyaout";
import StepperWrapper from "../../components/SurveyGenerator/FormBuilder/StepperWrapper";
import {
  Formiz,
  FormizStep,
} from "../../components/SurveyGenerator/FormBuilder/StepperWrapper/Formiz";
import FormRenderer from "../../components/SurveyGenerator/FormRenderer";
import { BASE_URL } from "../../config/api";
import ACTIONS from "../../config/constants/Actions";
import { default as enMsg } from "../../config/constants/locales/en-us.json";
import { default as frMsg } from "../../config/constants/locales/fr-fr.json";
import { resolver } from "../../helper/FormGeneratorHelper/conditionalLogic";
import { useForm } from "../../hooks/FormGeneratorHooks";
import { formulaValues } from "../../state/FormGeneratorState/fomulaValuesAtom";
import { statsValues } from "../../state/FormGeneratorState/statsValuesAtom";
import {
  updateUserForcedVisitsVisibilityAtom,
  userAtom,
} from "../../state/auth";
import { socketAtom } from "../../state/socket";
import { studyWriterConfigAtom } from "../../state/studyWriterConfig";
import { visteAtom } from "../../state/visteAtom";
import { User } from "../../types/user";
//import history from "../../history";
import { useHistory } from "react-router-dom";
type Props = any;
const DisplayForm: React.FC<Props> = ({
  onChange,
  onSubmit,
  formConfig,
  initialField,
  isExit = false,
  isExitReason = -1,
  ...props
}) => {
  const [lang, setLang] = useState("fr");
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [dataLoader, setDataLoader] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const form = useForm();
  const [user] = useAtom<User | null>(userAtom);
  const [, handleUserForcedVisitsVisibility] = useAtom(
    updateUserForcedVisitsVisibilityAtom
  );
  const [formData, setFormData] = useAtom(visteAtom);
  const [studyWriterConfig] = useAtom(studyWriterConfigAtom) as any;
  const [socket, setSocket] = useAtom(socketAtom) as any;
  const [connected, setConnected] = useState(true);
  const [formulaValuesAtom, setFormulaValuesAtom] = useAtom(
    formulaValues
  ) as any;

  const [statsValuesAtom, setStatsValuesAtom] = useAtom(statsValues) as any;
  const history = useHistory();
  const { t } = useTranslation();
  useEffect(() => {
    setDataLoader(true);
    setTimeout(() => {
      setDataLoader(false);
    }, 200);
  }, [props.data._id, formData?._id]);

  const handleSubmit = (values) => {
    switch (user?.type) {
      case "writer":
        let res: any[] = formData?.sections;
        res.map((section) => {
          section.fields.map((field) => {
            if (values.hasOwnProperty(`${field.field_name}`))
              field.value =
                field.type === "Checkboxes" &&
                Array.isArray(values[`${field.field_name}`])
                  ? values[`${field.field_name}`].join("||")
                  : values[`${field.field_name}`];

            section.status = "VALID_BY_WRITER";
            if (field.type === "RepeaterField") {
              field.value = JSON.stringify(values[`${field.field_name}`]);
            }
          });
        });
        onSubmit(res);
        break;
      default:
        break;
    }
  };

  const autoQueringVerifApi = async () => {
    try {
      setWaiting(true);
      const res = await autoQueringVerif(
        props.data?._id,
        studyWriterConfig?.centerId?._id
      );

      if (res && res.newForm) {
        setTimeout(() => {
          setWaiting(false);
        }, 200);
      } else {
        setTimeout(() => {
          setWaiting(false);
        }, 200);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const mySocket: Socket = io(BASE_URL, {
      forceNew: true,
      reconnectionAttempts: 100,
      timeout: 10000,
      transports: ["websocket"],
      auth: {
        token: localStorage.getItem("token"),
        tenantFormReact: localStorage.getItem("tenantId"),
      },
    });
    mySocket.emit(ACTIONS.JOIN, {
      roomId: `${props.data._id}`,
      userId: user?._id,
      tenantId: user?.tenantId,
    });

    // Listen for 'formData' event from the backend and update the state
    mySocket.on("formData", (formData) => {
      setFormData(formData);
    });
    mySocket.on("formulaValues", (formulaValuesSocket) => {
      setFormulaValuesAtom(formulaValuesSocket);
    });
    mySocket.on("newFormulaValues", (newFormulaValuesSocket) => {
      setFormulaValuesAtom(newFormulaValuesSocket);
    });
    mySocket.on("statsValues", (statsValuesSocket) => {
      setStatsValuesAtom(statsValuesSocket);
    });
    mySocket.on("newStatsValues", (newStatsValuesSocket) => {
      setStatsValuesAtom(newStatsValuesSocket);
    });
    mySocket.on("userCountUpdate", (count) => {});
    // Listen for the 'message' event

    mySocket.on(
      "forcedVisitsVisibilityChange",
      (UserForcedVisitsVisibilityUpdate) => {
        handleUserForcedVisitsVisibility(
          JSON.parse(UserForcedVisitsVisibilityUpdate)
        );
      }
    );

    setSocket(mySocket);

    mySocket.on("connect", () => {
      setConnected(true);
    });

    mySocket.on("disconnect", () => {
      setConnected(false);
    });

    // Clean up the socket connection on component unmount
    return () => {
      mySocket.disconnect();
      mySocket.close();
    };

    // Clean up the socket connection on component unmount
  }, []);

  /*********POPUP(Save FormData when clicking the back bouton)**************/

  // useEffect(() => {
  //   const handleNavigate = (e) => {
  //     if (formData?.status === "VALID_BY_WRITER" && formData?.submitted) {
  //       return true;
  //     }
  //     const confirmationMessage =
  //       "You have unsaved changes. Are you sure you want to leave?";

  //     if (!window.confirm(confirmationMessage)) {
  //       console.log("Cancel the navigation");
  //       // Cancel the navigation if the user clicks "Cancel" on the confirmation dialog.
  //       e.returnValue = "";
  //       return false;
  //     }
  //     console.log("Confirm Navigation");
  //     // Perform additional actions before navigation if needed.
  //     if (socket) {
  //       socket.emit("SUBMIT_FORM", {
  //         roomId: formData?._id,
  //         status: "VALID_BY_WRITER",
  //       });
  //     }
  //     return true;
  //   };

  //   // Assuming you are using React Router, you can use the following event for navigation.
  //   const historyUnlisten =
  //     formData?.status === "VALID_BY_WRITER" && !formData?.submited
  //       ? history.listen(handleNavigate)
  //       : () => {};

  //   return () => {
  //     historyUnlisten();
  //   };
  // }, [history, formData]);

  useEffect(() => {
    let local = localStorage.getItem("i18nextLng");

    if (local) {
      setLang(local);
    }
    return () => {
      if (
        user?.type === "writer" &&
        studyWriterConfig &&
        studyWriterConfig.studyId &&
        studyWriterConfig.studyId.status !== "END" &&
        formData?.status !== "LOCKED"
      ) {
        autoQueringVerifApi();
      }
      setFormData();
    };
  }, []);

  const forceShowVisitsForParticipant =
    user?.forceShowVisits?.[formData?.participantId || ""];

  const userIsAnInvestigator = user?.type === "writer";
  const writerBelongsToWrongProfileGroup =
    studyWriterConfig?.roleId?._id !== formData?.roleId;
  const writerHasForcedAccess = Array.isArray(forceShowVisitsForParticipant)
    ? forceShowVisitsForParticipant.includes(formData?.formTemplateId)
    : false;
  const writerShouldHaveReadOnlyAccess =
    userIsAnInvestigator &&
    writerBelongsToWrongProfileGroup &&
    writerHasForcedAccess;

  return !form || dataLoader ? (
    <>
      <Spinner />
      <Modal showModal={waiting}>
        <div> Waiting...</div>
      </Modal>
    </>
  ) : (
    <IntlProvider
      locale={lang}
      defaultLocale="en"
      messages={lang === "fr" ? frMsg : enMsg}
    >
      <Formiz
        connect={form}
        onValidSubmit={
          user?.type === "writer" ? handleSubmit : (v) => console.log(v)
        }
        onChange={() => props?.setIsEditing(true)}
        //²onInvalid={() => console.log("onInvalid form", form.isStepValid)}
      >
        {socket && (
          <div className="flex items-center align-baseline justify-center absolute -top-8 right-0 text-xs">
            <p
              className={`w-2 h-2 rounded-full mx-2 ${
                connected ? "bg-green-500" : "bg-red-400"
              }`}
            ></p>
            <>
              {connected
                ? t("missingWording.connected")
                : t("missingWording.disconnected")}{" "}
            </>
          </div>
        )}
        <MultiStepsLayout
          submitLabel={
            user?.type !== "writer"
              ? t("missingWording.submitLabel2")
              : t("missingWording.submitLabel")
          }
          isEditing={props.isEditing}
        >
          <PageHeader setShouldUpdate={setShouldUpdate}>
            {formData?.name ? formData?.name : "#Loading.."}
            <Heading fontSize="lg" mb={4} mt={4}>
              {formData?.description && (
                <>
                  <ArrowRightIcon
                    height={"12px"}
                    width={"12px"}
                    mr={2}
                    mb={1}
                    color={"#4565f6"}
                  />
                  <>{formData?.description}</>
                </>
              )}
            </Heading>
          </PageHeader>
          <>
            <div className="   p-4 w-full rounded-lg mb-4">
              <StepperWrapper title="Dots stepper" />

              {formData?.sections.map((step, i) => {
                return (
                  <FormizStep
                    label={step.sectionName}
                    name={`${step.sectionName + "-" + step.stepNbr}`}
                    isEnabled={
                      step.conditionLogic && step.conditionLogic.status
                        ? user?.type === "writer"
                          ? resolver(step.conditionLogic, form.values)
                          : resolver(step.conditionLogic, form.values)
                        : true
                    }
                  >
                    <FormRenderer
                      formDataId={formData?._id || props?.data?._id}
                      key={i}
                      stepNbr={step.stepNbr}
                      elements={step.fields}
                      values={form.values}
                      form={form}
                      fieldOnSubmit={true}
                      setShouldUpdate={setShouldUpdate}
                      isExit={formData.isExitReason}
                      readOnly={writerShouldHaveReadOnlyAccess}
                    ></FormRenderer>
                  </FormizStep>
                );
              })}
            </div>
          </>
          <Modal showModal={!connected}>
            <div className="bg-white p-4" style={{ minWidth: "500px" }}>
              <span className="text-justify flex justify-center font-semibold ">
                {t("missingWording.lostWebSocketConnction")}
              </span>
              <Spinner wh nhs classNames="" />
            </div>
            <div className="absolute bottom-1 right-36 left-36 flex flex-row justify-between"></div>
          </Modal>
        </MultiStepsLayout>
      </Formiz>
    </IntlProvider>
  );
};

export default DisplayForm;
